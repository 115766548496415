.login_page {
    display: block;
    width: 100%;

}

.login_form {
    max-width: 30rem;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.login_error_wr{
    display: flex;
    justify-content: center;
}
.login_error {
    color: red;
    font-size: 1rem;
    min-height: 1.5rem;
    max-width: 20rem;
}
