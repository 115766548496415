.loading_icon {
    margin: 0.5em auto 0.5em;

}

.loading_icon__wrapper {
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    color: white;
}

.loading_icon__wrapper__fullpage {
    margin: 30% auto;
    color: white;
}
